@import '~@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

#okta-sign-in {
  &.main-container {
    margin-top: 20px;
  }

  .auth-org-logo {
    display: block;
    margin: 0 auto;
    max-width: 200px;
    max-height: 60px;
  }
}
