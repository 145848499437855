mat-paginator.variant-list-paginator {
  mat-form-field.mat-paginator-page-size-select {
    margin-bottom: 0; // Override OA style
  }
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  background-color: inherit;
}
