@import 'src/styles/one-design/variables';

mat-card.variant-detail-card {
  min-height: 100px;
  width: 100%;
  padding: $one-dimension-3x $one-dimension-2x !important;

  // Header
  mat-card-header {
    .mat-card-header-text {
      margin: 0;
      width: 100%;
    }

    mat-card-title.mat-card-title {
      & .mat-caption {
        margin-bottom: $one-dimension-half;
      }

      .annotation-subtitle {
        font-size: 12px;
        font-weight: normal;
        line-height: 14px;
      }
    }

    .mat-card-avatar {
      border-radius: 0%;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 12px;
    }

    .card-avatar-small {
      margin-top: $one-dimension;
    }

    mat-card-subtitle {
      margin-bottom: 0;
    }
  }

  // Content
  mat-card-content {
    padding: 0 !important;
    margin: 24px 0 !important;

    .annotation-content-title-container {
      padding-bottom: $one-dimension-3x;
    }
  }

  // Footer
  mat-card-footer {
    padding-bottom: 0 !important;
    margin: 0 !important;
    position: absolute;
    bottom: 24px;
  }
}

.card-width-md {
  width: 640px !important;
}

mat-card.curated-card-content {
  mat-card-content {
    @include cair-secondary-link();
  }
}
