@import 'src/styles/one-design/variables';

// Generic styles

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.hidden {
  display: none !important;
}

.no-wrap {
  white-space: nowrap;
}

.word-wrap {
  word-break: break-word; // fix the wrap of question mark on chrome
  overflow-wrap: break-word;
}

.truncate-text {
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.base-font-enforced {
  font-size: $cair-base-font !important;
}

// Global link disabled class style
a {
  &.disabled-link {
    color: $one-color-gray-500 !important;
    cursor: not-allowed;
    text-decoration: none !important;
    opacity: 0.8;

    &:hover,
    &:focus {
      color: $one-color-gray-500 !important;
    }
  }
}

app-variant-pin-icon mat-icon {
  height: $one-text-font-size-px-16;
  width: $one-text-font-size-px-16;
}
