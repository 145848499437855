@import '@one/design-tokens/scss/cobas/light/variables';

// Updating styles for OWC nav secondary navbar migration

owc-double-global-area {
  display: flex;
  flex-direction: column;
  position: fixed;

  top: $one-spacer-48 + 1px; //together with higher index to hide the shadow of global header
  z-index: 1002; // 1 higher than global header to hide the shadow

  [slot='primary'] {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-right: $one-spacer-0;
    border-bottom: none;

    .owc-page-header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      max-width: $one-media-breakpoint-l-min - $one-spacer-40;
      width: 100%;
      padding: $one-spacer-0 $one-spacer-24;
      margin: $one-spacer-0 auto;

      owc-typography {
        font-size: $one-text-font-size-px-16;
      }
    }
  }
}
