@import '../one-design/variables';

.page-container {
  margin-left: auto;
  margin-right: auto;
}

.header {
  position: fixed;
  top: $one-spacer-0;
  left: $one-spacer-0;
  z-index: 1002; // .page-header(the blue page header) z-index is 1001
  background-color: $one-color-white;
  width: $one-sizer-base-100-p;
  border-bottom: 1px solid $one-color-a1;
  box-shadow: $one-shadow;
}

.header-container {
  height: $dimension-header-height;
  max-width: $dimension-width;
}

.main-view {
  width: $one-sizer-base-100-p;

  @media print {
    margin-top: $one-spacer-0;
    padding: $one-spacer-0;
  }
}

//Page layout
.page-header {
  position: fixed;
  top: $dimension-header-height + 1px; //together with higher index to hide the shadow of global header
  z-index: 1002; // 1 higher than global header to hide the shadow
  width: $one-sizer-base-100-p;
  background-color: $one-color-white;
  box-shadow: $one-shadow;
}

.page-header-container {
  max-width: $dimension-width;
  height: $dimension-page-header-height;
  padding: $one-spacer-0 $dimension-space-md;
}

.page-footer-container {
  max-width: $dimension-width;
  height: $dimension-sticky-footer-height;
  padding: $one-spacer-0 $dimension-space-md;
}

.page-content {
  max-width: $dimension-width;
  margin-top: ($dimension-header-height + $dimension-page-header-height);
  padding: $one-spacer-0 $dimension-space-md $dimension-space-md $dimension-space-md;
}

.page-content-no-header {
  width: $dimension-width;
  margin-top: $dimension-header-height;
  padding: $dimension-space-md;
}

.page-content-section {
  padding-top: $one-dimension-4x;
}

.page-fixed-footer {
  background-color: $one-color-white;
  width: $one-sizer-base-100-p;
  box-shadow: $one-shadow-top;
}

.page-container-left {
  width: 740px;
}

.page-error {
  text-align: center;
}

.account-section {
  position: relative;

  .account-section-container {
    margin-left: 242px;
    width: 742px;
    max-width: 742px;
    min-width: 742px;
  }
}

app-root {
  display: flex;
  flex-direction: column;
  min-height: $one-sizer-base-100-p;

  app-footer-bar {
    margin-top: auto;
  }
}
