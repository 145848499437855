@import 'src/styles/one-design/variables';

.cair-color-gray-400 {
  color: $one-color-gray-400;
}

.cair-color-gray-500 {
  color: $one-color-gray-500;
}

.cair-color-gray-600 {
  color: $one-color-gray-600;
}

.cair-color-gray-700 {
  color: $one-color-gray-700;
}

.cair-color-gray-800 {
  color: $one-color-gray-800;
}

.cair-color-gray-900 {
  color: $one-color-gray-900;
}

.bold-text {
  font-weight: 500;
}

.underline-text {
  text-decoration: underline;
}

.error-msg {
  color: $one-color-red-a-300;
}

// Custom link style - secondary link
@mixin cair-secondary-link {
  a {
    color: $one-color-gray-900 !important;
    text-decoration: underline !important;
    font-weight: 400 !important;
  }

  a:hover {
    color: $one-color-blue-900 !important;
  }
}

.secondary-link {
  @include cair-secondary-link();
}

// To get a superscript effect for the Registered symbol
span.registered-symbol {
  vertical-align: super;
  font-size: 0.5em;
}
