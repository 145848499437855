//class for icon size
.mat-icon.md-10 {
  font-size: 10px;
}
.mat-icon.md-12 {
  font-size: 12px;
}
.mat-icon.md-14 {
  font-size: 14px;
}
.mat-icon.md-16 {
  font-size: 16px;
}
.mat-icon.md-18 {
  font-size: 18px;
}
.mat-icon.md-24 {
  font-size: 24px;
}
.mat-icon.md-36 {
  font-size: 36px;
}
.mat-icon.md-48 {
  font-size: 48px;
}

@mixin resistance-therapy-icon() {
  width: 34px;
  height: 34px;
  background-size: 34px;
  position: relative;
  background-image: url('/assets/icons/therapy_resistance_cause_icon.png');
}

@mixin sensitive-therapy-icon() {
  width: 24px;
  height: 24px;
  background-size: 24px;
  position: relative;
  background-image: url('/assets/icons/therapy_sensitive_icon.png');
}

.resistance-therapy-icon {
  @include resistance-therapy-icon();
}

.sensitive-therapy-icon {
  @include sensitive-therapy-icon();
}

.sidebar-icon:after {
  content: url('/assets/icons/sidebar.svg');
}

@mixin molecular-match-logo() {
  height: 0.9cm;
  width: 135px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('/assets/images/molecular-match-copy.png');
}

owc-icon.md-14 {
  font-size: 14px;
}
