/* You can add global styles to this file, and also import other style files */
@import '@one/web-components/dist/owc/owc.css';
@import '@one/icons/dist/one-icons-legacy.css';
@import '~@one/icons/dist/one-icons-outlined.css';
@import '~@one/icons/dist/one-icons-filled.css';
@import '@one/design-tokens/css/legacy/light/variables.css';

@import 'one-design/one-design';
@import 'one-design/one-custom-style';
@import 'nmp/nmp';
