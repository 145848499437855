// This file was created according to the OAK installation instructions at:
// https://v2-angular.onedesign.roche.com/one-installation

@import '@angular/material/theming';

// Custom Theming and Variables for OneAngular Kit
@import '~@one/angular-kit/styles/theming';
// Include the OneDesign Icons. We include this here so that you only
// have to load a single css file for OneDesign Icons in your app.
// Be sure that you only ever include this mixin once!
@import '@one/icons/dist/one-icons-legacy.css';

// This will automatically load in the Roboto Fontface.
// In case you want to use a different font, make sure to update
// they "body" tag and remove this line.
@import '~roboto-fontface/css/roboto/roboto-fontface.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include one-core();

// Additionally modifies components for the OneAngularKit
@include one-angular-theme();

html,
body {
  height: 100%;
}
// Includes the Roboto font per default in your body
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.one-theme-dark {
  @include one-angular-theme($one-theme-dark);
}
