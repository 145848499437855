// Contains styles that are specific to the NMP product

@import 'layout';
@import 'loader';
@import 'base';
@import 'font';
@import 'icon';
@import 'card';
@import 'pdf';
@import 'okta';
@import 'toaster';
@import 'paginator';
@import 'elements';
@import 'dot-marker';
@import 'mat_icons';

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  background-color: $one-color-blue-100;

  &.white-background {
    background-color: $one-color-white;
  }
}
