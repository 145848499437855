// This file contains overrides to certain OneDesign styles

@import 'variables';
@import '../owc/table';
@import '../owc/navbar';
@import '../owc/dialog';
@import '../owc/dropdown';
@import '../owc/card';
@import '../owc/checkbox';

// Added for one design bug https://code.roche.com/onedesign/angular-kit/-/issues/232
.one-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline
  .mat-form-field-flex {
  height: auto !important;
}

// One design adds box shadow for tabs used under topheader
.one-topbar .mat-tab-header {
  border-top: none !important;
  box-shadow: unset !important;
}

.one-section {
  margin-top: $one-dimension-7x;
  margin-bottom: $one-dimension-3x;
}

.one-field-section {
  margin-bottom: $one-dimension-3x;
}

.one-sub-text-dimension {
  margin-top: $one-dimension-half;
  display: block;
}

.one-form-field.mat-form-field-type-mat-input {
  width: 100%;
}

.page-header-container span.mat-subheading-1,
span.mat-title {
  margin: 0;
}

.one-form-field.mat-form-field-type-mat-select .one-error-validation-icon {
  color: $one-color-alarm;
}

mat-form-field.mat-form-field {
  margin-bottom: $one-dimension-2x;
}

// OA adds 0 padding to mat-card-content if mat-title is no present
.mat-card.one-card .mat-card-content.no-title-card {
  padding: $one-dimension-2x;
}

.mat-card.one-card {
  // This override is to make OWC modal dialog to appear on top. Somehow, OAK card was appearing on top of overlay due to z-index prop.
  z-index: auto;
}

// TODO: Added to override the material sub-title font size. Remove once implemented a better way.
mat-card-subtitle.mat-body-2 {
  font: 400 14px/16px Roboto, 'Helvetica Neue', sans-serif !important;
}

.mat-card-content {
  font-size: $cair-base-font;
}

.mat-button,
.mat-raised-button,
.mat-stroked-button,
.mat-cell,
.mat-dialog-content,
mat-list-item.mat-list-item {
  font-size: $cair-base-font !important;
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  display: none;
}

.mat-tab-header-pagination.mat-tab-header-pagination-after,
.mat-tab-header-pagination.mat-tab-header-pagination-before {
  box-shadow: none;
}

.one-checkbox-small {
  .mat-checkbox-inner-container {
    height: 12px;
    width: 12px;
  }
  .mat-checkbox-label {
    margin: $one-dimension $one-dimension 0 0;
  }
}

// OA adds 24px bottom padding by default in cards.
// Use this class in <mat-card> to override if needed
.card-no-bottom-padding > *:last-child {
  padding-bottom: 0px !important;
}

// Expand the list to the edges of the card + make header width 100%
.card-with-list {
  .mat-card-header-text {
    width: 100%;
  }

  .mat-card-content {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .mat-table {
      .mat-row:hover {
        cursor: pointer;
      }

      .mat-header-cell,
      .mat-cell {
        padding: $one-dimension;
      }

      .mat-row .mat-cell:first-of-type,
      .mat-header-row .mat-header-cell:first-of-type {
        padding-left: $one-dimension-2x !important;
      }

      .mat-row .mat-cell:last-of-type,
      .mat-header-row .mat-header-cell:last-of-type {
        padding-right: $one-dimension-2x !important;
      }
    }
  }
}

.spacer {
  flex: 1 1 auto;
}

mat-form-field.one-select.one-select-filled.mat-form-field-appearance-fill {
  .mat-select-value,
  .mat-form-field-label-wrapper {
    font-size: $cair-base-font;
  }

  .mat-form-field-flex {
    padding: 0;

    .mat-form-field-label {
      // To match the color of other form fields
      color: $one-color-gray-600;
    }
  }

  .mat-select-arrow-wrapper {
    transform: none;
  }
}

.dialog-actions-top-shadow {
  // Copied from OAK expandable table to match the styles: https://angular.onedesign.roche.com/one-table-documentation/examples
  box-shadow: 0 -4px 4px -2px rgb(0 0 0 / 12%);
}
