// TODO: Reevaluate if we need this after integrating OAK > Snackbar
@import '~angular2-toaster/toaster.css';
@import 'src/styles/one-design/variables';

.toast-success {
  background-color: $one-color-ok;
  color: $one-color-white;
}

.toast-error {
  background-color: $one-color-alarm;
  color: $one-color-white;
}

.toast-info {
  background-color: $one-color-gray-800;
  color: $one-color-white;
}

.toast-warning {
  background-color: $one-color-warning;
  color: $one-color-gray-900;
}

.toaster-icon-custom-position {
  background-position: 100% 15px;
}
