@use 'sass:math';
@import '@one/angular-kit/styles/variables';
@import '@one/design-tokens/scss/cobas/light/variables';

$one-color-alarm: $one-color-red-a-300;
$one-color-success: $one-color-green-a-300;
$one-color-ok: $one-color-green-a-300;
$one-color-warning: $one-color-yellow-a-300;

// Alpha colors
// TODO define color
$one-color-a1: (rgba(rgb(0, 0, 0), 0.16)); //#000000 16%

// # Dimensions
$one-dimension-half: 4px;
$one-dimension: 8px;
$one-dimension-2x: $one-dimension * 2;
$one-dimension-3x: $one-dimension * 3;
$one-dimension-4x: $one-dimension * 4;
$one-dimension-5x: $one-dimension * 5;
$one-dimension-6x: $one-dimension * 6;
$one-dimension-7x: $one-dimension * 7;
$dimension-width: 1024px;
$dimension-header-height: 48px;
$dimension-page-header-height: 54px;
$dimension-sticky-footer-height: 54px;
$dimension-page-footer-height: 62px;

// Layout
$dimension-page-header-height: 54px;

// Padding and margin
$one-dimension-base: 20px;
$dimension-space-lg: $one-dimension-base * 2;
$dimension-space-md: $one-dimension-base;
$dimension-space-sm: math.div($one-dimension-base, 2);

// Elevation and shadows
$one-shadow: 0px 2px 3px 0px $one-color-a1;
$one-shadow-top: 0px -2px 3px 0px $one-color-a1;
$margin-for-one-shadow: 0px 2px 3px 0px;
$bottom-margin-for-one-shadow: 3px;

//Fonts
$cair-base-font: 14px;
$cair-font-12: 12px;
$cair-font-16: 16px;
$cair-font-20: 20px;
